import type { FlagNames } from 'plantiga-firebase/FeatureFlags';
import type { Feature } from 'plantiga-firebase/Team/useTeamFeature';

import type { Principal } from './principal';
import { isTierView } from './useTierViews';

export function SuperUserClaim(p: Principal) {
  return p.user?.super_user?.is_admin === true;
}

export function TeamFeatureClaim(feature: Feature) {
  return function teamFeatureClaim(p: Principal) {
    return Boolean(p.features[feature]);
  };
}

export function FeatureFlagClaim(flag: FlagNames) {
  return function featureFlagClaim(p: Principal) {
    return Boolean(p.flags[flag] || p.flags.default || false);
  };
}

export function UserRoleClaim(role: NonNullable<Principal['user']['role']>) {
  return function userRoleClaim(p: Principal) {
    return p.user?.role === role;
  };
}

export function TeamRoleClaim(role: 'editor' | 'viewer') {
  return function teamRoleClaim(p: Principal) {
    // for now, only demo users cannot edit
    return role !== 'editor' || !p.demo;
  };
}

export function TierClaim(tier: number) {
  return function claim(p: Principal) {
    return p.user.super_user || p.tier >= tier;
  };
}

export function ViewClaim(view: string) {
  return function claim(p: Principal) {
    if (isTierView(view)) {
      return p.views.has(view);
    }
    return true;
  };
}
